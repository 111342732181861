import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// We can inject some CSS into the DOM.
const styles = {
  root: {
    backgroundColor: '#ff0000',
    transition: 'background-color .3s ease-out',
    border: 0,
    color: 'white',
    boxShadow: '0 2px 6px 0 rgba(0,0,0,.3)!important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ff3f3f'
    },
    '&:disabled': {
      color: '#aaa',
      backgroundColor: '#efeff4'
    },
  },
};

function CancelButton(props) {
  const { classes, children, className, ...other } = props;

  return (
    <Button className={classNames(classes.root, className)} {...other}>
      {children || 'class names'}
    </Button>
  );
}

CancelButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(CancelButton);
