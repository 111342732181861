import React, { Component } from 'react';

import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';

import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import ShortRoundApi from './../../services/ShortRoundApi';
const suggestionHost = process.env.REACT_APP_SUGGESTION_HOST;
const mapboxToken = 'pk.eyJ1IjoidHRpZXJjZWxpbiIsImEiOiJjam4yeDBoeTA0YjR4M2twbjc5OGJ2d2Y4In0.9950HehpA0zAwWAqy1yOrg';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Aucun résultat
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
      style={{
        paddingLeft:'0.7em'
      }}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}
    noWrap={true}
    paragraph={false}
    style={{
      paddingLeft:'0.7em'
    }}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square style={{zIndex: 9999}} className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class LocationField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
      ],
      loadingSuggestions: false,
    };
  }

  geocodePlaceId(formattedAddress, placeId) {
    this.setState({
      geocoding: true,
    });

    ShortRoundApi.geocode(placeId).then((results)=>{
      const result = results[0];
      const {geometry} = result;
      const {location} = geometry;
      this.props.onLocationSelected({
        label: formattedAddress,
        position: location,
      });
    }).finally(()=>{
      this.setState({
        geocoding: false,
      });
    });
  }

  geocode(formattedAddress) {
    this.setState({
      geocoding: true,
    });
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${formattedAddress}.json?access_token=${mapboxToken}`).then((response)=>{
      return response.json();
    }).then((json)=>{
      console.log(json);
      const center = json.features[0].center;

      this.props.onLocationSelected({
        label: formattedAddress,
        position: {
          lat: center[1],
          lng: center[0],
        }
      });
    }).finally(()=>{
      this.setState({
        geocoding: false,
      });
    });
  }

  updateSuggestionsDebounced(value) {
    //console.log('updateSuggestionsDebounced', value);
    if(this.state.updateSuggestionsDebounce) {
      clearTimeout(this.state.updateSuggestionsDebounce);
    }

    const debounced = setTimeout(() => {
      this.updateSuggestions(value);
    }, 400);

    this.setState({
      updateSuggestionsDebounce: debounced,
    });
  }

  updateSuggestions(value) {
    if(!value) {
      return;
    }
    if(value.length < 3) {
      return;
    }
    console.log('updateSuggestions', value);

    this.setState({
      loadingSuggestions: true,
    });

    const myInit = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const myRequest = new Request(`${suggestionHost}/suggestion/v1/address?address=${value}`, myInit);
    fetch(myRequest).then((response)=>{
      //console.log(response)
      return response.json();
    }).then((json)=>{
      console.log(json);
      if(!json || !json.data || !json.data.items || !json.data.items.length) {
        return;
      }

      //console.log('sugg updated');
      this.setState({
        loadingSuggestions: false,
        options: json.data.items.map((item)=>{
          //console.log(item);
          return {
            label: item.formattedAddress,
            value: item,
          };
        }),
      });
      //console.log(json);
    }).catch((err)=>{
      this.setState({
        loadingSuggestions: false,
      });
    });
  }

  handleChoiceChange(value) {
    if(!value || !value.value) {
      this.props.onLocationSelected();
      return;
    }
    if(value.value.lat && value.value.lng) {
      this.props.onLocationSelected({
        label: value.value.formattedAddress,
        placeId: value.value.placeId,
        position: {lat: value.value.lat, lng: value.value.lng},
        terminal: value.value.terminal,
        type: value.value.type,
      });
      return;
    }
    //console.log(value);

    if(value.value.placeId) {
      this.geocodePlaceId(value.label, value.value.placeId);
    }
    else {
      this.geocode(value.label);
    }
  }

  handleInputChange(value) {
    //console.log('input change', value);
    this.updateSuggestionsDebounced(value);
  }

  customFilter(object) {
    return true;
  }

  render() {
    const { selectedOption } = this.state;
    const { classes, theme } = this.props;

    const selectStyles = {
      singleValue: base => ({
        ...base,
        backgroundColor:'red',
      }),
      input: base => ({
        ...base,
        padding:'1em',
        paddingLeft:'0.7em',
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };
/*
    return (<IntegrationDownshift
        label={this.props.label}
        suggestions={this.state.options}
      />);
      */
    //console.log(this.state.options);
    return (
      <Select
        classes={classes}
        value={selectedOption}
        textFieldProps={{
          variant:'outlined',
          label: this.props.label,
          InputLabelProps: {
            shrink: true,
          },
        }}
        styles={selectStyles}
        options={this.state.options}
        placeholder={this.props.placeholder}
        onChange={(v)=>{this.handleChoiceChange(v)}}
        onInputChange={(v)=>{this.handleInputChange(v)}}
        components={components}
        isClearable
        filterOption={this.customFilter}
        isLoading={this.state.loadingSuggestions || this.state.geocoding}
        loadingMessage={()=>'Recherche'}
      />
    )
  }
}

export default withStyles(styles, { withTheme: true })(LocationField);
