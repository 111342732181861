import React from 'react';

import FareList from './FareList';

import ShortRoundApi from './../../services/ShortRoundApi';

class NextDepartures extends React.Component {
  state = {
    fares: [],
    loading: false,
  };

  componentDidMount() {
    this.setState({
      loading: true,
    });

    ShortRoundApi.listTrips({sortBy: 'departureAt', size: 16, sortOrder: 'asc', states: 'INCOMING,ONDUTY'}).then((result)=>{
      this.setState({fares: result.fares})
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      this.setState({
        loading: false,
      });
    })
  }

  render() {
    return (
      <FareList datefield="departureAt" fares={this.state.fares} loading={this.state.loading}/>
    );
  }
}

export default NextDepartures;
