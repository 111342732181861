import React from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { withStyles } from '@material-ui/core/styles';

import FareList from './fares/FareList';

function getModalStyle() {
  return {

  };
  /*
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
  */
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '80%',
    maxHeight: '80%',
    overflow: 'auto',
    marginTop: '5%',
    marginLeft: '10%',
    //width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
});

const SearchResultsModal = (props) => {
  const { classes } = props;
  return (
  <div>
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={props.handleClose}
    >
      <div style={getModalStyle()} className={classes.paper}>
      <IconButton aria-label="Clear" style={{float:'right'}} onClick={props.handleClose}>
        <ClearIcon fontSize="small" />
      </IconButton>

        <FareList fares={props.results} onItemClicked={props.handleClose}/>
      </div>
    </Modal>
  </div>

  );
}

export default withStyles(styles)(SearchResultsModal);
