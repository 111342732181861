import React from "react";

import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";

import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import SubmitButton from "./../../common/SubmitButton";

import SedanIcon from "./images/panel_sedan.png";
import AccessIcon from "./images/panel_access.png";
import VanAccessIcon from "./images/panel_van_access.png";

import VanIcon from "./images/panel_van.png";

const panels = {
  SEDAN: {
    people: 4,
    luggages: 3,
    icon: SedanIcon,
  },
  VAN: {
    people: 7,
    luggages: 7,
    icon: VanIcon,
  },
  ACCESS: {
    people: 1,
    luggages: 1,
    icon: AccessIcon,
  },
  VAN_ACCESS: {
    people: 3,
    luggages: 3,
    icon: VanAccessIcon,
  },
};

function getPanel(key) {
  return panels[key];
}

const PanelItem = (props) => {
  const panel = getPanel(props.id);
  return (
    <Card
      style={{
        marginBottom: "1em",
        padding: "1em",
        paddingTop: "1.5em",
        paddingBottom: "1.5em",
      }}
    >
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={3}>
          <img src={panel.icon} width="100%" alt={props.name} />
        </Grid>
        <Grid item xs={6}>
          <b>{props.name}</b>
          <Grid container alignItems="center" style={{ fontSize: "0.8em" }}>
            <Grid item xs={6}>
              <PersonIcon />
              {panel.people} pers
            </Grid>
            <Grid item xs={6}>
              <WorkIcon />
              {panel.luggages} bagages
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <SubmitButton
            disabled={props.availability !== "DRIVER_FOUND"}
            color="primary"
            variant="contained"
            fullWidth
            size="medium"
            style={{ fontSize: "1rem", padding: "0.5rem" }}
            onClick={props.onPanelSelected}
          >
            Choisir
          </SubmitButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PanelItem;
