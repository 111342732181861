import React from 'react';

import { Link, Route } from "react-router-dom";

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import ShortRoundApi from './../services/ShortRoundApi';

import SearchResultsModal from './SearchResultsModal';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  smallGrow: {
    flexGrow: 0.5,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchButton: {
    margin:'0.5rem',
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});

class SearchAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      searching: false,
    };
    console.log('SearchAppBar', props);
  }

  resetSearch() {
    this.setState({
      searchResults: null,
    });
  }

  handleSubmit(event, history) {
    console.log(history);
    event.preventDefault();
    if(!this.state.query) {
      return;
    }
    console.log(this.state.query);
    this.setState({searching: true});

    ShortRoundApi.searchTrips(this.state.query).then((results)=>{
      //console.log(results);
      if(!results || !results.fares) {
        return;
      }
      if(results.fares.length > 1) {
        this.setState({
          searchResults: results.fares,
        });
        return;
      }
      this.props.onResults(results, history);
    }).catch((err)=>{
      console.log(err);
    }).then(()=>{
      this.setState({searching: false});
    })
  }

  handleChange(event) {
    this.setState({
      query: event.target.value,
    });
  }

  render() {

    return (
      <Route render={({ history}) => {
        const { classes } = this.props;
        return (
          <div className={classes.root}>
            <AppBar position="fixed" color="default">
              <Toolbar>
                <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                  <Link to="/">
                    <img width="128px" src="https://storage.googleapis.com/allocab-wordpress.appspot.com/logo4.png" alt="logo mutuaide"/>
                  </Link>
                </Typography>
                <div className={classes.smallGrow}></div>
                <div className={classes.search}>
                  <form noValidate autoComplete="off" onSubmit={(e)=>this.handleSubmit(e, history)}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <Grid container alignItems={'center'}>
                      <Grid item>
                        <InputBase
                          placeholder="N° de dossier..."
                          value={this.state.query}
                          onChange={(e)=>this.handleChange(e)}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button className={classes.searchButton} disabled={ this.state.searching || !this.state.query } variant="contained" type="submit" size="small">Rechercher</Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <div className={classes.grow}></div>
              </Toolbar>
            </AppBar>

            <SearchResultsModal
              open={this.state.searchResults ? true : false}
              results={this.state.searchResults}
              handleClose={()=>this.resetSearch()}
              />
          </div>
        );
      }} />
    );



  }


}

SearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchAppBar);
