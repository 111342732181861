import React from 'react';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import PanelItem from './panels/PanelItem';

const PanelList = (props) => {
  const items = props.panels.map(panel => {
    //console.log(panel);
    return (
      <div key={panel.key}>
        <PanelItem key={panel.key} availability={panel.availability} name={panel.info.name} id={panel.info.key} panelNameKey={panel.panelNameKey} onPanelSelected={()=>{props.onPanelSelected(panel)}}></PanelItem>
      </div>
    )
  });

  return (<List>{items}</List>);
}

class PanelSelection extends React.Component {
  render() {
    return (
      <PanelList panels={this.props.panels} onPanelSelected={this.props.onPanelSelected}/>
    )
  }
}

export default PanelSelection;
