import React from 'react';

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FareListItem from './FareListItem';

class FareList extends React.Component {

  constructor(props) {
    super(props);
  }

  handleItemClicked() {
    if(!this.props.onItemClicked) {
      return;
    }
    this.props.onItemClicked();
  }

  render() {
    const loading = <TableRow><TableCell>chargement</TableCell></TableRow>;

    const items = (
      this.props.fares.map((fare)=>{
        return (
          <FareListItem
            key={fare.id}
            id={fare.id}
            onItemClicked={()=>{this.handleItemClicked()}}
            createdAt={fare.createdAt}
            departureAt={fare.departureAt}
            state={fare.state}
            fileNumber={fare.fileNumber}
            passenger={`${fare.passengerFirstName} ${fare.passengerLastName}`}
            />
        );
      })
    );


    if(!this.props.fares.length && this.props.loading) {
      return (
        <p style={{
            padding:'1em',
            color: '#aaa'
          }}>
          Chargement ...
        </p>
      )
    }

    if(!this.props.fares.length && !this.props.loading) {
      return (
        <p style={{
            padding:'1em',
            color: '#aaa'
          }}>
          Aucune réservation à afficher.
        </p>
      )
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>N°</TableCell>
            <TableCell>Date de commande</TableCell>
            <TableCell>Date de départ</TableCell>
            <TableCell>N° dossier</TableCell>
            <TableCell>Passager</TableCell>
            <TableCell>Statut</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items}
        </TableBody>
      </Table>
    );
  }
}

export default FareList;
