import React from "react";
import moment from "moment";
import Lottie from "react-lottie";

import buffer from "@turf/buffer";
import bboxPolygon from "@turf/bbox-polygon";
import bbox from "@turf/bbox";
import lineToPolygon from "@turf/line-to-polygon";

import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

import SubmitButton from "./../common/SubmitButton";

import ShortRoundApi from "./../../services/ShortRoundApi";
import GeoApi from "./../../services/GeoApi";

import BookingResume from "./BookingResume";
import DirectionDefinition from "./DirectionDefinition";
import PanelSelection from "./PanelSelection";
import PassengerInformations from "./PassengerInformations";

import SimpleModal from "./../common/SimpleModal";

import successAnimation from "./success.json";

import mutuaideLogo from "./../../logo.png";

import departIcon from "./depart.png";
import arriveeIcon from "./arrivee.png";

const turf = require("@turf/helpers");

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoidHRpZXJjZWxpbiIsImEiOiJjam4yeDBoeTA0YjR4M2twbjc5OGJ2d2Y4In0.9950HehpA0zAwWAqy1yOrg",
});

class Booker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchSubmissionError: false,
      currentStep: 0,
    };
  }

  reset() {
    this.setState({
      currentStep: 0,
      completed_0: false,
      completed_1: false,
      completed_2: false,
      completed_3: false,
      panel: null,
      booking: null,
      polyline: null,
      pickup: null,
      dropoff: null,
      passenger: null,
      mapItems: null,
    });
  }

  goToStep(nbStep) {
    const { currentStep } = this.state;
    console.log("currentStep", currentStep);
    if (currentStep === 3) {
      nbStep = 0;
    }

    this.setState({
      currentStep: nbStep,
    });

    if (nbStep === 0) {
      this.reset();
    }
  }

  resetErrors() {
    this.setState({
      searchSubmissionErrorTitle: null,
      searchSubmissionError: false,
    });
  }

  handleSearchSubmission(direction) {
    if (!direction.pickup) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError: "Merci d'indiquer une adresse de départ",
      });
      return;
    }
    if (!direction.dropoff) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError: "Merci d'indiquer une adresse d'arrivée",
      });
      return;
    }
    if (!direction.delay === "FUTURE" && !direction.departureAt) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError: "Merci d'indiquer une date et heure de départ",
      });
      return;
    }
    if (
      direction.delay === "FUTURE" &&
      moment(direction.departureAt).isBefore(moment().add(20, "minutes"))
    ) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError:
          "Merci d'indiquer une date à plus de 30 minutes.",
      });
      return;
    }

    this.search(direction);
  }

  search(direction) {
    //console.log('searching direction', direction);
    this.setState({
      loading: true,
    });

    const {
      pickup,
      dropoff,
      delay,
      departureAt,
      transport,
      allocabToken,
    } = direction;

    const date = delay === "FUTURE" ? departureAt : null;

    ShortRoundApi.askQuotation(
      pickup.position.lat,
      pickup.position.lng,
      pickup.label,
      dropoff.position.lat,
      dropoff.position.lng,
      dropoff.label,
      date,
      transport,
      allocabToken
    )
      .then((booking) => {
        if (!booking.quotations || !booking.quotations.length) {
          this.setState({
            searchSubmissionErrorTitle: "Réservation impossible",
            searchSubmissionError:
              "Tous nos chauffeurs sont occupés, veuillez selectionner une heure de départ plus éloignée dans le temps.",
          });
          return;
        }

        console.log("booking has been granted");
        console.log(booking);

        direction.eta = booking.delay;

        this.setState({
          completed_0: true,
          booking: {
            delay: booking.delay,
            distance: booking.distance,
            duration: booking.duration,
            id: booking.bookingId,
            token: booking.token,
          },
          direction: direction,
          loading: false,
          drivers: booking.drivers,
          panels: booking.quotations
            .map((quotation) => {
              return {
                availability: quotation.availability,
                key: quotation.key,
                panelNameKey: quotation.panelNameKey,
                info: quotation.info,
              };
            })
            .filter((quotation) => {
              console.log(quotation);
              return quotation.info;
            }),
        });

        this.goToStep(1);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  selectPanel(panel) {
    this.goToStep(2);
    console.log(panel);
    this.setState({
      completed_1: true,
      panel,
    });
  }

  handleConfirm(
    phoneNumber,
    costCenter,
    fileNumber,
    firstName,
    lastName,
    comment
  ) {
    if (!costCenter) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError: "Merci de choisir un centre de coût",
      });
      return;
    }
    if (!fileNumber) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError: "Merci d'indiquer un numéro de dossier",
      });
      return;
    }
    if (!firstName) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError: "Merci d'indiquer le prénom du passager",
      });
      return;
    }
    if (!lastName) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError: "Merci d'indiquer le nom du passager",
      });
      return;
    }
    if (!phoneNumber) {
      this.setState({
        searchSubmissionErrorTitle: "Information manquante",
        searchSubmissionError:
          "Merci d'indiquer le numéro de téléphone du passager",
      });
      return;
    }
    this.confirm(
      phoneNumber,
      costCenter,
      fileNumber,
      firstName,
      lastName,
      comment
    );
  }

  confirm(phoneNumber, costCenter, fileNumber, firstName, lastName, comment) {
    this.setState({
      loading: true,
    });

    ShortRoundApi.confirmQuotation(
      this.state.booking.id,
      this.state.panel.key,
      phoneNumber,
      costCenter,
      fileNumber,
      firstName,
      lastName,
      comment,
      this.state.booking.token
    )
      .then((confirmation) => {
        this.goToStep(3);
        console.log(confirmation);
        this.setState({
          loading: false,
          completed_2: true,
          completed_3: true,
          passenger: {
            firstName,
            lastName,
            phoneNumber,
            costCenter,
            fileNumber,
            comment,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generateMapItems(pickup, dropoff, polyline) {
    if (!pickup && !dropoff) {
      this.setState({
        mapItems: null,
      });
      return;
    }

    const bounds = [];
    //console.log(pickup);
    let pickupCoordinate;
    if (pickup) {
      pickupCoordinate = [pickup.position.lng, pickup.position.lat];
      bounds.push(pickupCoordinate);
      if (!dropoff) {
        bounds.push(pickupCoordinate);
      }
    }
    let dropOffCoordinate;
    if (dropoff) {
      dropOffCoordinate = [dropoff.position.lng, dropoff.position.lat];
      bounds.push(dropOffCoordinate);
      if (!pickup) {
        bounds.push(dropOffCoordinate);
      }
    }

    let poly = bboxPolygon([
      bounds[0][0],
      bounds[0][1],
      bounds[1][0],
      bounds[1][1],
    ]);
    let points;
    if (polyline) {
      points = polyline.map((c) => [c[0], c[1]]);
      const lineString = turf.lineString(points);
      poly = lineToPolygon(JSON.parse(JSON.stringify(lineString)));
    }
    const buffered = buffer(poly, 1.5, { units: "kilometers" });
    const box = bbox(buffered);
    const bounded = [
      [box[0], box[1]],
      [box[2], box[3]],
    ];
    this.setState({
      mapItems: {
        polyline: points,
        bounds: bounded,
        dropoffPosition: dropOffCoordinate,
        pickupPosition: pickupCoordinate,
      },
    });
  }

  updatePolyline(pickup, dropoff) {
    if (!pickup || !dropoff) {
      this.setState({
        polyline: null,
      });
      return this.generateMapItems(pickup, dropoff, null);
    }
    if (
      pickup &&
      dropoff &&
      JSON.stringify(pickup.position) === JSON.stringify(dropoff.position)
    ) {
      this.setState({
        polyline: null,
      });
      return this.generateMapItems(pickup, dropoff, null);
    }
    GeoApi.direction(pickup.position, dropoff.position)
      .then((resp) => {
        console.log(resp);
        this.setState({
          polyline: resp.polyline,
        });
        this.generateMapItems(pickup, dropoff, resp.polyline);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePickupUpdate(pickup) {
    console.log("handlePickupUpdate", pickup);
    this.setState({
      pickup,
    });
    this.updatePolyline(pickup, this.state.dropoff);
  }
  handleDropOffUpdate(dropoff) {
    this.setState({
      dropoff,
    });

    this.updatePolyline(this.state.pickup, dropoff);
  }

  componentDidUpdate() {
    const { map } = this.state;
    if (map) {
      setTimeout(() => {
        map.resize();
      }, 500);
    }
  }

  onStyleLoad(map) {
    this.setState({ map });
  }

  render() {
    const defaultOptions = {
      loop: false,
      animationData: successAnimation,
    };

    const { currentStep } = this.state;

    let currentStepComponent;
    if (currentStep === 0) {
      currentStepComponent = (
        <DirectionDefinition
          handlePickupUpdate={(pickup) => {
            this.handlePickupUpdate(pickup);
          }}
          handleDropoffUpdate={(dropoff) => {
            this.handleDropOffUpdate(dropoff);
          }}
          confirmDirection={(direction) => {
            this.handleSearchSubmission(direction);
          }}
          loading={this.state.loading}
          direction={this.state.direction}
        />
      );
    } else if (currentStep === 1) {
      currentStepComponent = (
        <PanelSelection
          panels={this.state.panels}
          onPanelSelected={(panel) => this.selectPanel(panel)}
        />
      );
    } else if (currentStep === 2) {
      currentStepComponent = (
        <PassengerInformations
          onConfirmation={(
            phoneNumber,
            costCenter,
            fileNumber,
            firstName,
            lastName,
            comment
          ) =>
            this.handleConfirm(
              phoneNumber,
              costCenter,
              fileNumber,
              firstName,
              lastName,
              comment
            )
          }
          loading={this.state.loading}
        />
      );
    } else if (currentStep === 3) {
      currentStepComponent = (
        <div>
          <hr />
          <Typography variant="h4" gutterBottom color="primary">
            <Lottie
              options={defaultOptions}
              height={"3rem"}
              width={"3rem"}
              style={{ float: "left" }}
            />
            Réservation confirmé
          </Typography>

          <hr />

          <SubmitButton
            onClick={() => {
              this.reset();
            }}
            fullWidth
          >
            Nouvelle réservation
          </SubmitButton>
        </div>
      );
    }

    let side;

    const { mapItems } = this.state;

    if (mapItems) {
      const { pickupPosition, dropoffPosition, polyline, bounds } = mapItems;

      const layers = [];
      if (pickupPosition) {
        layers.push(
          <Marker coordinates={pickupPosition} anchor="center">
            <img
              style={{
                height: "4em",
              }}
              src={departIcon}
            />
          </Marker>
        );
      }
      if (dropoffPosition) {
        layers.push(
          <Marker coordinates={dropoffPosition} anchor="center">
            <img
              style={{
                height: "4em",
              }}
              src={arriveeIcon}
            />
          </Marker>
        );
      }

      if (polyline) {
        layers.push(
          <Layer
            key="itinerary"
            type="line"
            id="itinerary"
            paint={{ "line-width": 3, "line-dasharray": [1, 1] }}
          >
            <Feature coordinates={polyline} />
          </Layer>
        );
      }

      side = (
        <Map
          style="mapbox://styles/mapbox/streets-v9"
          fitBounds={bounds}
          onStyleLoad={(map) => this.onStyleLoad(map)}
          containerStyle={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {layers}
        </Map>
      );
    } else {
      side = (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item>
            <img src={mutuaideLogo} alt="logo mutuaide" />
          </Grid>
        </Grid>
      );
    }

    const stepper = (
      <Stepper activeStep={this.state.currentStep}>
        <Step
          key="direction"
          disabled={this.state.currentStep > 2 || this.state.currentStep < 1}
        >
          <StepButton
            onClick={() => {
              this.goToStep(0);
            }}
            completed={this.state.completed_0}
          >
            Itinéraire
          </StepButton>
        </Step>
        <Step
          key="gamme"
          disabled={this.state.currentStep > 2 || this.state.currentStep < 2}
        >
          <StepButton
            onClick={() => {
              this.goToStep(1);
            }}
            completed={this.state.completed_1}
          >
            Gamme
          </StepButton>
        </Step>
        <Step key="passager" disabled={true}>
          <StepButton
            onClick={() => {
              this.goToStep(2);
            }}
            completed={this.state.completed_2}
          >
            Passager
          </StepButton>
        </Step>
      </Stepper>
    );

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          {stepper}
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            paddingLeft: "2em",
          }}
        >
          <SimpleModal
            title={this.state.searchSubmissionErrorTitle}
            open={this.state.searchSubmissionError ? true : false}
            message={this.state.searchSubmissionError}
            handleClose={() => this.resetErrors()}
          />
          <BookingResume
            currentStep={this.state.currentStep}
            direction={this.state.direction}
            panel={this.state.panel}
            passenger={this.state.passenger}
            drivers={this.state.drivers}
          />
          {currentStepComponent}
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            paddingRight: "2em",
          }}
        >
          <div
            style={{
              borderRadius: "1em",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {side}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default Booker;
