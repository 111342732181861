import React from 'react';

import buffer from '@turf/buffer';
import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';
import lineToPolygon from '@turf/line-to-polygon';
import envelope from '@turf/envelope';
import distance from '@turf/distance';

import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";
import departIcon from './depart.png';
import arriveeIcon from './arrivee.png';
import carIcon from './car.png';

const turf = require('@turf/helpers');

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoidHRpZXJjZWxpbiIsImEiOiJjam4yeDBoeTA0YjR4M2twbjc5OGJ2d2Y4In0.9950HehpA0zAwWAqy1yOrg'
});

class TripMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate() {
    const {  map } = this.state;
    if(map) {
      setTimeout(()=>{
          map.resize();
      }, 1);
    }
  }

  onStyleLoad(map) {
    this.setState({map});
  }

  render() {

    const {pickupPosition, dropoffPosition, driverPosition, polyline, driverPolyline} = this.props;

    let pickup;
    let dropoff;
    const bounds = [];
    const layers = [];
    if(pickupPosition) {
      pickup = [pickupPosition.lon, pickupPosition.lat];
      bounds.push(pickup);
      if(!dropoff) {
        bounds.push(pickup);
      }
      layers.push(
        <Marker
        key="pickup"
        coordinates={pickup}
        anchor="center">
        <img
        alt="Départ"
        style={{
          height: '4em',
        }}
        src={departIcon}/>
        </Marker>
      );
    }
    if(driverPosition) {
      const driver = [driverPosition.lng, driverPosition.lat];
      bounds.push(driver);
      layers.push(
        <Marker
        key="driver"
        coordinates={driver}
        anchor="center">
        <img
        alt="Chauffeur"
        style={{
          height: '4em',
        }}
        src={carIcon}/>
        </Marker>
      );
    }
    if(dropoffPosition) {
      dropoff = [dropoffPosition.lon, dropoffPosition.lat];
      bounds.push(dropoff);
      if(!pickup) {
        bounds.push(dropoff);
      }
      layers.push(
        <Marker
        coordinates={dropoff}
        key="dropoff"
        anchor="center">
        <img
        alt="Arrivée"
        style={{
          height: '4em',
        }}
        src={arriveeIcon}/>
        </Marker>
      );

    }

    console.log('bounds', bounds);
    let poly = envelope(turf.featureCollection(bounds.map((p) => {
      console.log(p);
        return turf.point(p);
      }
    )));
    console.log('poly 1', poly);

    //let poly;
/*
    let poly = envelope(turf.featureCollection(bounds.map((p) => {
        return turf.point(p);
      }
    )));
    */


    //let poly = bboxPolygon([bounds[0][0],bounds[0][1],bounds[1][0],bounds[1][1]]);

    if(polyline && polyline.length) {
      console.log('add trip polyline');
      poly = envelope(turf.featureCollection(polyline.map((p) => {
          return turf.point(p);
        }
      )));

      layers.push(
        <Layer
        key="itinerary"
        type="line"
        id="itinerary"
        paint={{ "line-width": 3, 'line-dasharray': [1,1] }}
        >
        <Feature coordinates={polyline}/>
        </Layer>
      );
    }

    if(driverPolyline && driverPolyline.length) {
      console.log('add driverPolyline');
      if(!polyline) {
        console.log('driverPolyline', driverPolyline);
        poly = envelope(turf.featureCollection(driverPolyline.map((p) => {
            return turf.point(p);
          }
        )));
        //console.log('poly', poly);
        //console.log(turfLength(poly));
      }
      layers.push(
        <Layer
        key="driverItinerary"
        type="line"
        id="driverItinerary"
        paint={{ "line-width": 3, 'line-color': '#aaaaaa', 'line-dasharray': [1,1]  }}
        >
        <Feature coordinates={driverPolyline}/>
        </Layer>
      );
    }

    console.log('lets buffer polygon');

    console.log('poly', poly);
    const from = turf.point(poly.geometry.coordinates[0][0]);
    console.log('from',from);
    const to = turf.point(poly.geometry.coordinates[0][1]);
    console.log('to',to);
    const options = { units: 'kilometers' };
    const dist = distance(from, to, options);
    console.log('distance', dist);
    let viewMargin = 0.5;
    if(dist) {
      viewMargin = dist * 20 / 100;
    }

    const buffered = buffer(poly, viewMargin, {units:'kilometers'});
    const box = bbox(buffered);
    const bounded = [[box[0],box[1]],[box[2],box[3]]];

    console.log('bounded', bounded);

    return (
      <div style={{
        borderRadius: '1em',
        overflow:'hidden',
        height: "100%",
      }}>
        <Map
        fitBounds={bounded}
        style="mapbox://styles/mapbox/streets-v9"
        onStyleLoad={map => this.onStyleLoad(map)}
        containerStyle={{
          height: '100%',
          minHeight: '350px',
          width: '100%',
          overflow:'hidden'

        }}>
        {layers}
        </Map>
      </div>
    );
  }
}

export default TripMap;
