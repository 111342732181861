import React from 'react';
import ReactDOM from 'react-dom';

import Grid from '@material-ui/core/Grid';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import countries from './countries.json';

const GoogleLibphonenumber = require('google-libphonenumber');
const PNF = GoogleLibphonenumber.PhoneNumberFormat;
const phoneUtil = GoogleLibphonenumber.PhoneNumberUtil.getInstance();
const AsYouTypeFormatter  = GoogleLibphonenumber.AsYouTypeFormatter;

const CountryList = (props) => {
  return countries.map((country)=>{
    const label = `${country.name} (${country.dial_code})`;
    return (<option key={country.code} value={country.code}>{label}</option>)
  });
}

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth: 0,
    }
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  render() {
    return (
      <FormControl fullWidth margin="normal"   variant="outlined">
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="phone-number-country-auto-width">Indicatif</InputLabel>
          <NativeSelect
            value={this.props.value}
            disabled={this.props.disabled}
            onChange={(e)=>{ this.props.onCountryChanged(e) }}
            input={<OutlinedInput name="phone-number-country" id="phone-number-country-auto-width"  labelWidth={this.state.labelWidth}/>}
          >

          <CountryList/>
        </NativeSelect>
      </FormControl>
    )
  }
};

class PhoneNumberField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      country: 'FR',
      number: props.number || '',
    }
  }

  componentDidMount() {
    this.determinePhoneNumberCountryAndFormat(this.state.number);
  }

  determinePhoneNumberCountryAndFormat(e164Format) {
    try {
      const parsed = phoneUtil.parseAndKeepRawInput(e164Format, 'ZZ');
      const validNumber = phoneUtil.isValidNumber(parsed);
      if(!validNumber) {
        return;
      }
      const country = phoneUtil.getRegionCodeForNumber(parsed);
      const number = phoneUtil.format(parsed, PNF.NATIONAL);
      const update = {
        country,number,validNumber,e164Format,
      };
      this.setState(update);
      this.props.onChange(update);
    }
    catch(e) {

    }
  }


  handleCountryChange(v) {
    const country = v.target.value;
    console.log('handleCountryChange', v.target.value);
    this.setState({
      country,
    });
    this.formatPhoneNumber(country, this.state.number);
  }

  handleNumberChange(v) {
    console.log('handleNumberChange', v.target.value);
    const number = v.target.value;
    console.log('handleNumberChange', v.target.value);
    this.setState({
      number,
    });
    this.formatPhoneNumber(this.state.country, number);
  }

  formatPhoneNumber(country, phoneNumber) {
    if(!phoneNumber) {
      const update = {
        number: '',
        validNumber: false,
        e164Format: null,
      };
      this.setState(update);
      this.props.onChange(update);
      return;
    }

    let number;
    let validNumber = false;
    let e164Format;
    try {
      const parsed = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
      validNumber = phoneUtil.isValidNumber(parsed);
      if(validNumber) {
        e164Format = phoneUtil.format(parsed, PNF.E164);
        number = phoneUtil.format(parsed, PNF.NATIONAL);
      }
    }
    catch(e) {

    }

    if(!number) {
      phoneNumber = phoneNumber.replace(new RegExp(' ', 'g'), '');
      const formatter = new AsYouTypeFormatter(country);
      for(const digit of phoneNumber) {
        number = formatter.inputDigit(digit);
      }
      formatter.clear();
    }

    const update = {
      number,
      validNumber,
      e164Format,
      country,
    };
    this.setState(update);
    this.props.onChange(update);
  }

  render() {
    return (<div>
        <Grid container spacing={8}>
          <Grid item xs={5}>
            <CountrySelect
              disabled={this.props.disabled}
              value={this.state.country}
              onCountryChanged={(e)=>this.handleCountryChange(e)}/>
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="phone-number"
              label="Numéro de téléphone"
              disabled={this.props.disabled}
              variant="outlined"
              value={this.state.number}
              onChange={(e)=>{this.handleNumberChange(e)}}
              fullWidth
              margin="normal"
              />
          </Grid>
        </Grid>

      </div>);
  }
}

export default PhoneNumberField;
