import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

class DriverItem extends React.Component {

  handleClick() {
    console.log('handleClick');
    if(!this.props.onItemClicked) {
      return;
    }
    this.props.onItemClicked();
  }

  render() {
    return (
      <TableRow>
        <TableCell><a href={'https://dash-dot-allocab-spyglass.appspot.com/driver/' + this.props.id} target="_blank">{this.props.name}</a></TableCell>
        <TableCell>{this.props.phoneNumber}</TableCell>
      </TableRow>
    );
  }
}

export default DriverItem;
