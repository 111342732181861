import React from 'react';

import Moment from 'react-moment';

import { Link } from "react-router-dom";

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

function formatState(state) {
  if('INCOMING' === state) {
    return 'Confirmée';
  }
  else if('CANCELLED' === state) {
    return 'Annulée';
  }
  else if('DONE' === state) {
    return 'Terminée';
  }
  else if('APPROACHING' === state) {
    return 'En approche';
  }
  else if('IN_DA_PLACE' === state) {
    return 'Sur place';
  }
  return 'En cours';
}

class FareItem extends React.Component {

  handleClick() {
    console.log('handleClick');
    if(!this.props.onItemClicked) {
      return;
    }
    this.props.onItemClicked();
  }

  render() {
    return (
      <TableRow>
        <TableCell><Link to={`/trip/${this.props.id}`} onClick={()=>this.handleClick()}>R{this.props.id}</Link></TableCell>
        <TableCell>
          <Moment locale="fr" format="ddd DD MMM HH:mm">{this.props.createdAt}</Moment>
        </TableCell>
        <TableCell>
          <Moment locale="fr" format="ddd DD MMM HH:mm">{this.props.departureAt}</Moment>
        </TableCell>
        <TableCell>{this.props.fileNumber}</TableCell>
        <TableCell>{this.props.passenger}</TableCell>
        <TableCell>{formatState(this.props.state)}</TableCell>
      </TableRow>
    );
  }
}

export default FareItem;
