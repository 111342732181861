import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Booker from './../components/booker';
import NextDepartures from './../components/fares/NextDepartures';
import LastBookedFares from './../components/fares/LastBookedFares';

class Home extends React.Component {
  render() {
    return (
      <div>
        <Typography variant="h4"  gutterBottom>Réserver</Typography>
        <Paper
          style={{
            paddingBottom: '2em'}}
          ><Booker/></Paper>
        <div style={{height:'3rem'}}></div>
          <Typography variant="h4"  gutterBottom>Prochains départs</Typography>
          <Paper><NextDepartures/></Paper>
          <div style={{height:'3rem'}}></div>
        <Typography variant="h4"  gutterBottom>Dernières commandes</Typography>
        <Paper><LastBookedFares/></Paper>
        <div style={{height:'3rem'}}></div>
      </div>
    );
  }
}

export default Home;
