import React from 'react';

import moment from 'moment';

import {DayPicker, DayPickerSingleDateController} from 'react-dates';
import TextField from '@material-ui/core/TextField';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleDateFocus() {
    console.log('focus');
    this.setState({
      focused: true,
    });
  }

  handleDateBlur() {
    console.log('blur');
    this.setState({
      focused: false,
    });
  }

  handleDaySelection(day) {
    console.log(day.format());
    this.props.onChange(day);
    this.handleDateBlur();
  }

  render() {
    const picker = (
      <div style={{
        position: 'absolute',
        zIndex: 999999,
      }}>
      <DayPickerSingleDateController
        date={this.props.date}
        isOutsideRange={(d)=>moment().startOf('day').isAfter(d)}
        onOutsideClick={() => { this.handleDateBlur() }}
        enableOutsideDays={false}
        onDateChange={(date)=>{this.handleDaySelection(date)}}
        hideKeyboardShortcutsPanel
      />
      </div>
    );

    return (
      <div>
        <TextField
          id="departure-date"
          label="Date"
          variant="outlined"
          value={this.props.date.format('ddd DD MMM YYYY')}
          onFocus={() => this.handleDateFocus()}
          onChange={(e)=>{this.handleFirstNameChange(e)}}
          fullWidth
          margin="normal"
          />
          {this.state.focused ? picker : <div></div>}
        </div>
    )
  }
}

export default DatePicker;
