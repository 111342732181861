import React from 'react';

const GoogleLibphonenumber = require('google-libphonenumber');
const PNF = GoogleLibphonenumber.PhoneNumberFormat;
const phoneUtil = GoogleLibphonenumber.PhoneNumberUtil.getInstance();

function determinePhoneNumberCountryAndFormat(e164Format) {
  try {
    const parsed = phoneUtil.parseAndKeepRawInput(e164Format, 'ZZ');
    const validNumber = phoneUtil.isValidNumber(parsed);
    if(!validNumber) {
      return;
    }
    const country = phoneUtil.getRegionCodeForNumber(parsed);
    const number = phoneUtil.format(parsed, PNF.NATIONAL);
    return {
      country,number,validNumber,e164Format,
    };
  }
  catch(e) {
    return {
      number: e164Format,
      e164Format,
      country: 'ZZ',
      validNumber: false,
    };
  }
}

const PhoneNumber = props => {
  return (<span>
      { determinePhoneNumberCountryAndFormat(props.number).number }
    </span>
  );
};

export default PhoneNumber;
