import React from 'react';

import moment from 'moment';

import {DayPicker} from 'react-dates';

import SubdirectoryArrowRight from '@material-ui/icons/SubdirectoryArrowRight';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import TimePicker from './TimePicker';
import DatePicker from './DatePicker';

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    const {hour,minute} = this.computeFields(this.props.date);

    this.state = {
      hour,minute,
      focused: false,
    };
  }

  computeFields(date) {
    const hour = date.hour();
    const minute = Math.round(date.minute() / 10)*10;
    return {hour,minute};
  }

  handleHourUpdate(hour) {
    this.setState({
      hour,
    });
    this.computeNewDate(this.props.date, hour, this.state.minute);
  }

  handleMinuteUpdate(minute) {
    this.setState({
      minute,
    });
    this.computeNewDate(this.props.date, this.state.hour, minute);
  }

  handleDateChanged(date) {
    this.computeNewDate(date, this.state.hour, this.state.minute);
  }

  computeNewDate(date, hour, minute) {
    const d = moment(date).startOf('day').hour(hour).minute(minute);
    this.setState({date: d});
    this.props.onDateTimeUpdated(d);
  }


  render() {

    return (
      <Grid container spacing={24} alignItems="center">
        <Grid item xs={1}>
          <SubdirectoryArrowRight/>
        </Grid>
        <Grid item xs={5}>

          <DatePicker
            focused={this.state.focused}
            date={this.props.date}
            onChange={(date)=>{this.handleDateChanged(date)}}
            />

        </Grid>
        <Grid item xs={3}>
          <TimePicker
            label="Heure"
            name="depature-hour"
            value={this.state.hour}
            onChange={(hour)=>this.handleHourUpdate(hour)}
            max={24}
            step={1}/>
        </Grid>
        <Grid item xs={3}>
          <TimePicker
            value={this.state.minute}
            onChange={(minute)=>this.handleMinuteUpdate(minute)}
            label="Minute" name="depature-minute" max={60} step={10}/>
        </Grid>
      </Grid>
    )
  }


}

export default DateTimePicker;
