import React from 'react';

import ReactDOM from 'react-dom';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth: 0,
    };
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handleChange(value) {
    //console.log(value.target.value);
    this.props.onChange(value.target.value);
  }

  render() {
    const slots = [];
    for(let s = 0; s < this.props.max; s += this.props.step) {
      const label = s < 10 ? `0${s}` : s;
      slots.push({value:s,label});
    }

    return (
      <FormControl fullWidth margin="normal"  variant="outlined">
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor={this.props.name}>{this.props.label}</InputLabel>
        <NativeSelect
          value={this.props.value}
          onChange={(v)=>this.handleChange(v)}
          input={<OutlinedInput name={this.props.name} id={this.props.name} labelWidth={this.state.labelWidth} />}
        >

        {slots.map((m)=>{

          return (
            <option key={m.label} value={m.value}>{m.label}</option>
          )

        })}

        </NativeSelect>
      </FormControl>
    );
  }
}



export default TimePicker;
