import React from "react";
import ReactDOM from "react-dom";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";

import SubmitButton from "./SubmitButton";
import PhoneNumberField from "./phone-number-field";

class PassengerInformationsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      costCenter: props.costCenter || "",
      fileNumber: props.fileNumber || "",
      firstName: props.firstName || "",
      lastName: props.lastName || "",
      phoneNumber: {
        number: props.phoneNumber,
      },
      comment: props.comment || "",
      labelWidth: 0,
    };
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handleConfirmation() {
    console.log(this.state);
    this.props.onConfirmation(
      this.state.phoneNumber.validNumber
        ? this.state.phoneNumber.e164Format
        : null,
      this.state.costCenter,
      this.state.fileNumber,
      this.state.firstName,
      this.state.lastName,
      this.state.comment
    );
  }

  handleCostCenterChange(value) {
    //console.log(value.target.value);
    this.setState({
      costCenter: value.target.value,
    });
  }

  handleFileNumberChange(value) {
    //console.log(value.target.value);
    this.setState({
      fileNumber: value.target.value,
    });
  }

  handleFirstNameChange(value) {
    //console.log(value.target.value);
    this.setState({
      firstName: value.target.value,
    });
  }

  handleLastNameChange(value) {
    //console.log(value.target.value);
    this.setState({
      lastName: value.target.value,
    });
  }

  handlePhoneNumberChange(value) {
    console.log(value);
    //console.log(value.target.value);
    this.setState({
      phoneNumber: value.phone,
    });
  }

  handleCommentChange(value) {
    //console.log(value.target.value);
    this.setState({
      comment: value.target.value,
    });
  }

  render() {
    const submit = (
      <SubmitButton
        disabled={this.props.loading || this.props.disabled}
        onClick={() => {
          this.handleConfirmation();
        }}
        color="primary"
        variant="contained"
        fullWidth
        size="large"
        style={{
          marginTop: 8,
        }}
      >
        {this.props.disabled
          ? "Modification impossible"
          : this.props.loading
          ? "... En cours"
          : this.props.submitLabel}
      </SubmitButton>
    );

    return (
      <form>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel
                ref={(ref) => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="cost-center-auto-width"
              >
                Centre de coût
              </InputLabel>
              <Select
                value={this.state.costCenter}
                disabled={this.props.disabled}
                onChange={(e) => {
                  this.handleCostCenterChange(e);
                }}
                input={
                  <OutlinedInput
                    name="cost-center"
                    id="cost-center-auto-width"
                    labelWidth={this.state.labelWidth}
                  />
                }
              >
                <MenuItem value={"ASSISTANCE"}>Mutuaide Assistance</MenuItem>
                <MenuItem value={"SERVICES"}>Mutuaide Services</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="file-number"
              disabled={this.props.disabled}
              label="Numéro de dossier"
              variant="outlined"
              value={this.state.fileNumber}
              onChange={(e) => {
                this.handleFileNumberChange(e);
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>

        <Grid container spacing={8}>
          <Grid item xs={6}>
            <TextField
              id="first-name"
              label="Prénom"
              disabled={this.props.disabled}
              variant="outlined"
              value={this.state.firstName}
              onChange={(e) => {
                this.handleFirstNameChange(e);
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="last-name"
              label="Nom"
              disabled={this.props.disabled}
              variant="outlined"
              value={this.state.lastName}
              onChange={(e) => {
                this.handleLastNameChange(e);
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>

        <PhoneNumberField
          number={this.state.phoneNumber.number}
          disabled={this.props.disabled}
          onChange={(phone) => this.handlePhoneNumberChange({ phone })}
        />

        <div>
          <TextField
            id="informations"
            variant="outlined"
            label="Informations à transmettre au chauffeur"
            value={this.state.comment}
            disabled={this.props.disabled}
            onChange={(e) => {
              this.handleCommentChange(e);
            }}
            multiline
            fullWidth
            margin="normal"
          />
        </div>

        {submit}
      </form>
    );
  }
}

export default PassengerInformationsForm;
