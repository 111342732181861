import { captureException } from "@sentry/browser";

const PanelTranslator = require("./PanelTranslator");
const apiHost = process.env.REACT_APP_SERVER_HOST;

const bookingPath = "booking";
const bookingVersion = "1";
const askQuotationPath = "askQuotation";
const confirmQuotationPath = "confirmQuotation";
const getTransportPath = "getTransport";
const geocodePath = "geocode";

const tripPath = "trip";
const tripVersion = "1";
const listPath = "list";
const searchPath = "search";
const updatePath = "update";

const authPath = "auth";
const authVersion = "1";
const checkPath = "check";

const queryString = require("query-string");

//let apiToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.z8K2gar--0Hnd2v5xgKJ7dLzWZ9qpufpp3bRDl8OGHY";

let apiToken;

function fetchApi(method, url, payload) {
  const body = payload ? JSON.stringify(payload) : null;
  const myInit = {
    method: method,
    mode: "cors",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${apiToken}`,
    },
    body,
    cache: "default",
  };
  const myRequest = new Request(url, myInit);

  return new Promise((res, rej) => {
    fetch(myRequest)
      .then((response) => {
        if (response.status !== 200) {
          return rej({ code: response.status });
        }
        return response.json();
      })
      .then((json) => {
        if (json.error) {
          captureException(json.error);
          return rej(json.error);
        }
        res(json);
      })
      .catch((err) => {
        captureException(err);
        rej(err);
      });
  });
}

function checkTransport(lat, lng, date, number, carrier) {
  const payload = {
    carrier,
    number,
    arrivalAt: date,
    lat,
    lng,
  };
  return fetchApi(
    "POST",
    `${apiHost}/api/${bookingPath}/v${bookingVersion}/${getTransportPath}`,
    payload
  );
}

function checkAuth(token) {
  apiToken = token;
  return fetchApi(
    "GET",
    `${apiHost}/api/${authPath}/v${authVersion}/${checkPath}`
  );
}

function updateTrip(
  fareId,
  passengerPhoneNumber,
  costCenter,
  fileNumber,
  firstName,
  lastName,
  comment
) {
  const payload = {
    passengerPhoneNumber,
    costCenter,
    fileNumber,
    passengerFirstName: firstName,
    passengerLastName: lastName,
    comment,
  };
  return fetchApi(
    "POST",
    `${apiHost}/api/${tripPath}/v${tripVersion}/${fareId}/${updatePath}`,
    payload
  );
}

function searchTrips(query) {
  return fetchApi(
    "GET",
    `${apiHost}/api/${tripPath}/v${tripVersion}/${searchPath}?q=${query}`
  );
}

function getTripStatus(fareId) {
  return fetchApi(
    "GET",
    `${apiHost}/api/${tripPath}/v${tripVersion}/${fareId}/status`
  );
}

function getTrip(fareId) {
  return fetchApi(
    "GET",
    `${apiHost}/api/${tripPath}/v${tripVersion}/${fareId}`
  );
}

function listTrips(options) {
  const qs = options ? queryString.stringify(options) : "";
  return fetchApi(
    "GET",
    `${apiHost}/api/${tripPath}/v${tripVersion}/${listPath}?${qs}`
  );
}

function cancelTrip(tripId) {
  return fetchApi(
    "DELETE",
    `${apiHost}/api/${tripPath}/v${tripVersion}/${tripId}`
  );
}

function confirmQuotation(
  bookingId,
  selectedQuotationKey,
  passengerPhoneNumber,
  costCenter,
  fileNumber,
  firstName,
  lastName,
  comment,
  token
) {
  const payload = {
    bookingId,
    selectedQuotationKey,
    passengerPhoneNumber,
    costCenter,
    fileNumber,
    passengerFirstName: firstName,
    passengerLastName: lastName,
    comment,
    token,
  };
  return fetchApi(
    "POST",
    `${apiHost}/api/${bookingPath}/v${bookingVersion}/${confirmQuotationPath}`,
    payload
  );
}

function geocode(placeId) {
  const qs = queryString.stringify({
    place_id: placeId,
  });
  return fetchApi(
    "GET",
    `${apiHost}/api/${bookingPath}/v${bookingVersion}/${geocodePath}?${qs}`
  );
}

function askQuotation(
  startLat,
  startLng,
  startAddress,
  endLat,
  endLng,
  endAddress,
  departureAt,
  transport,
  allocabToken
) {
  const payload = {
    departureAt,
    start: {
      lat: startLat,
      lng: startLng,
      formattedAddress: startAddress,
    },
    end: {
      lat: endLat,
      lng: endLng,
      formattedAddress: endAddress,
    },
    transport,
    allocabToken,
  };

  return new Promise((res, rej) => {
    fetchApi(
      "POST",
      `${apiHost}/api/${bookingPath}/v${bookingVersion}/${askQuotationPath}`,
      payload
    )
      .then((booking) => {
        booking.quotations = booking.quotations.map((quotation) => {
          const info = PanelTranslator.getInformations(quotation.panelNameKey);
          quotation.info = info;
          return quotation;
        });
        res(booking);
      })
      .catch(rej);
  });
}

export default {
  askQuotation,
  confirmQuotation,
  getTrip,
  cancelTrip,
  listTrips,
  searchTrips,
  checkAuth,
  updateTrip,
  checkTransport,
  getTripStatus,
  geocode,
};
