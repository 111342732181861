const panels = {
  SEDAN: {
    name: "Berline",
    key: "SEDAN",
  },
  VAN: {
    name: "Van",
    key: "VAN",
  },
  ACCESS: {
    name: "Access",
    key: "ACCESS",
  },
  VAN_ACCESS: {
    name: "Van Access",
    key: "VAN_ACCESS",
  },
};

const panelIndex = {
  "ag1zfmFsbG9jYWItZGV2chILEglQYW5lbE5hbWUY-ZbBGww": panels.SEDAN,
  57691001: panels.SEDAN,

  ag5zfmFsbG9jYWItcHJvZHITCxIJUGFuZWxOYW1lGJ7olLcBDA: panels.SEDAN,
  384119838: panels.SEDAN,

  ag1zfmFsbG9jYWItZGV2chILEglQYW5lbE5hbWUYjPjXHAw: panels.VAN,
  60161036: panels.VAN,

  ag5zfmFsbG9jYWItcHJvZHITCxIJUGFuZWxOYW1lGJ6l_bQBDA: panels.VAN,
  379540126: panels.VAN,

  ag5zfmFsbG9jYWItcHJvZHIWCxIJUGFuZWxOYW1lGICA4a6So7gKDA: panels.ACCESS,
  5876997659574272: panels.ACCESS,

  ag1zfmFsbG9jYWItZGV2chYLEglQYW5lbE5hbWUYgIDo7NeyjQoM: panels.ACCESS,
  5688415707856896: panels.ACCESS,

  ag5zfmFsbG9jYWItcHJvZHIWCxIJUGFuZWxOYW1lGICA4eul8KYKDA: panels.VAN_ACCESS,
  5800483750428672: panels.VAN_ACCESS,

  ag1zfmFsbG9jYWItZGV2chYLEglQYW5lbE5hbWUYgIDomsvDngoM: panels.VAN_ACCESS,
  5763763220905984: panels.VAN_ACCESS,
  BERLINE: panels.SEDAN,
  VAN: panels.VAN,
  VAN_ACCESS: panels.VAN_ACCESS,
  ACCESS: panels.ACCESS,
};

function getInformations(panelNameKey) {
  return panelIndex[panelNameKey];
}

module.exports = {
  getInformations,
};
