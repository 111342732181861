import React from 'react';

import moment from 'moment';
import {SingleDatePicker} from 'react-dates';
import Moment from 'react-moment';
import 'moment-timezone';

import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import FlightLand from '@material-ui/icons/FlightLand';
import Train from '@material-ui/icons/Train';
import AirplanemodeActive from '@material-ui/icons/AirplanemodeActive';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import ShortRoundApi from './../../services/ShortRoundApi';

import CancelButton from './../common/CancelButton';

import DatePicker from './date-time-picker/DatePicker';

import airlines from './airlines.json';

const LineDescription = (props) => {

  const line = (
    <Grid container>
      <Grid item xs={12}>
        <p style={{
          fontSize:'0.8em',
        }}>
          Le passager sera pris en charge à son arrivée à la sortie de l'avion ou en tête de quai avec une pancarte nominative.
        </p>
      </Grid>
      <Grid item xs={1}>
        { props.airline ? <AirplanemodeActive/> : <Train/> }

      </Grid>
      <Grid item xs={5}>
        <b>{props.airline}</b>
        <div>{props.departure}</div>
      </Grid>
      <Grid item xs={1}>
        <ArrowForwardIos/>
      </Grid>
      <Grid item xs={5}>
        <AccessAlarm/><Moment format="HH:mm">{props.arrivalAt}</Moment>
        <div>{props.arrival}</div>
      </Grid>
    </Grid>
  );
  return line;
}

class TransportNumberField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      carrier: '',
      number: '',
      search: '',
      focused: false,
      date: props.date,
    };
  }



  handleSearchChange(event) {

    const value = event.target.value;
    console.log('handleSearchChange',value);
    this.setState({
      search: value,
      airline:null,
      carrier: null,
      number: value,
    });
    this.analyzeSearch(value);
  }

  analyzeSearch(value) {
    if(!value) {
      this.setState({
        carrier: null,
        transport: null,
        number: null,
      });
      return;
    }
    if(value.length < 3) {
      this.setState({
        carrier: null,
        transport: null,
        number: null,
      });
      return;
    }

    const regex = new RegExp(/(^.{2})(.*)/gm);
    const parts = regex.exec(value);
    const twoFirst = parts[1].trim().toUpperCase();
    const last = parts[2].trim().toUpperCase();
    const airline = airlines[twoFirst];

    const state = {
      search: airline ? `${twoFirst} ${last}` : value,
      carrier: airline ? twoFirst : null,
      number: airline ? last : value,
      airline,
    };

    this.setState(state);

    this.debouncedCheck({
      date: this.state.date,
      carrier: state.carrier,
      number: state.number,
    });
  }

  handleDateChanged(date) {
    console.log('handleDateChanged');
    this.setState({
      date,
    });
    this.debouncedCheck({
      date,
      carrier: this.state.carrier,
      number: this.state.number,
    });
  }

  handleDateFocusChanged(focused) {
    this.setState({
      focused,
    });
  }

  debouncedCheck(updatedState) {
    if(this.state.checkTransportDebounce) {
      clearTimeout(this.state.checkTransportDebounce);
    }

    const debounced = setTimeout(() => {
      this.checkTransport(updatedState);
    }, 400);

    this.setState({
      checkTransportDebounce: debounced,
      transport: null,
    });
  }

  disableTransport() {
    this.props.onTransportUpdated({disableTransport: true});
  }

  checkTransport(updatedState) {
    if(!updatedState.number) {
      return;
    }
    this.setState({
      loadingTransport: true,
    });
    ShortRoundApi.checkTransport(this.props.position.lat,this.props.position.lng,updatedState.date,updatedState.number,updatedState.carrier).then((result)=>{
      if('No transport found' === result.message ) {
        this.setState({
          transport: null,
        });
        this.props.onTransportUpdated({transport: result, date: updatedState.date});
        return;
      }

      if(moment(result.scheduledArrival).isBefore(moment())) {
        this.setState({
          transport: null,
        });
        this.props.onTransportUpdated({transport: result, date: updatedState.date});
        return;
      }

      console.log(result);
      this.setState({
        transport: result,
      });

      this.props.onTransportUpdated({transport: result, date: updatedState.date});
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      this.setState({
        loadingTransport: false,
      });
    });
  }

  render() {

    const transportFound = this.state.transport ? <LineDescription
      airline={this.state.airline}
      departureAt={this.state.transport.scheduledDeparture}
      arrivalAt={this.state.transport.scheduledArrival}
      departure={this.state.transport.departure.name}
      arrival={this.state.transport.arrival.name}/> : <div></div>;

    return (
      <div>

      <p style={{
        fontSize:'0.8em',
        marginBottom:0
      }}>
      <CancelButton onClick={()=>this.disableTransport()} style={{
        float: 'right',
        marginLeft:'1em'
      }}>non merci</CancelButton>
        Le départ correspond à une gare/aéroport, veuillez saisir le numéro de vol/train ainsi que la date de départ pour bénéficier de la garantie retard et de la prise en charge en tête de quai.

      </p>

      <Grid container spacing={8}>
        <Grid item xs={6}>
        <TextField
           id="standard-name"
           label="N° vol/train"
           value={this.state.search}
           onChange={(e)=>this.handleSearchChange(e)}
           variant="outlined"
           margin="normal"
         />
        </Grid>

        <Grid item xs={6}>


               <DatePicker
                 focused={this.state.focused}
                 date={this.state.date}
                 onChange={(date)=>{this.handleDateChanged(date)}}
               />
        </Grid>
      </Grid>




      <div></div>
      {!this.state.loadingTransport && !this.state.transport && this.state.search ? 'Ce numéro est introuvable pour la date donnée' : ''}
      {this.state.loadingTransport ? '...' : ''}
      {transportFound}

      </div>
    );
  }
}


export default TransportNumberField;
