import React from "react";

import Moment from "react-moment";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import AccessAlarm from "@material-ui/icons/AccessAlarm";

import SedanIcon from "./panels/images/panel_sedan.png";
import AccessIcon from "./panels/images/panel_access.png";
import VanAccessIcon from "./panels/images/panel_van_access.png";
import VanIcon from "./panels/images/panel_van.png";
import DriversResultModal from "../DriversResultModal";
import { Button } from "@material-ui/core";

const panels = {
  SEDAN: {
    people: 4,
    luggages: 3,
    icon: SedanIcon,
  },
  VAN: {
    people: 7,
    luggages: 7,
    icon: VanIcon,
  },
  ACCESS: {
    people: 1,
    luggages: 1,
    icon: AccessIcon,
  },
  VAN_ACCESS: {
    people: 3,
    luggages: 3,
    icon: VanAccessIcon,
  },
};

function getPanel(key) {
  return panels[key];
}

class BookingResume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDriversModal: false,
    };
  }

  closeDriverModal = () => {
    this.setState({
      openDriversModal: false,
    });
  }

  openDriverModal= () =>  {
    this.setState({
      openDriversModal: true,
    });
  }

  render() {
    const { currentStep } = this.props;

    let directionResume;
    let panelResume;
    let passengerResume;

    if (!currentStep) {
      return <div></div>;
    }

    const { pickup, dropoff, delay, departureAt, eta } = this.props.direction;
    const drivers = this.props.drivers;
    const driverTotal = drivers ?
    <Grid item xs={12}>
      <b>Chauffeurs</b> <br />
      {drivers.total} chauffeur disponible dans les 20kms <Button onClick={this.openDriverModal}>(voir list)</Button>
    </Grid> 
    : <Grid item xs={12}></Grid>;
    const driverModal = drivers ?
    <DriversResultModal
      open={this.state.openDriversModal ? true : false}
      results={drivers.drivers}
      handleClose={()=>this.closeDriverModal()}
    />
    : null;
    const asapDeparture = moment().add(eta, "minutes");

    const pickupAt =
      delay === "ASAP" ? (
        <Moment locale="fr" fromNow>
          {asapDeparture}
        </Moment>
      ) : (
        <Moment locale="fr" format="ddd DD MMM HH:mm" fromNow>
          {departureAt}
        </Moment>
      );

    directionResume = (
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={8}>
          <b>Départ</b>
          <br />
          {pickup.label}
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <center>
            <AccessAlarm />
          </center>
          {pickupAt}
        </Grid>

        <Grid item xs={12}>
          <b>Arrivée</b> <br />
          {dropoff.label}
        </Grid>
        {driverTotal}
        {driverModal}
      </Grid>
    );

    if (this.props.panel) {
      const panel = getPanel(this.props.panel.info.key);
      panelResume = (
        <Grid
          container
          spacing={8}
          alignItems="center"
          style={{
            marginTop: "1.5em",
          }}
        >
          <Grid item xs={3}>
            <img
              src={panel.icon}
              width="100%"
              alt={this.props.panel.info.name}
            />
          </Grid>
          <Grid item xs={9}>
            <b>{this.props.panel.info.name}</b>
            <Grid container alignItems="center" style={{ fontSize: "0.8em" }}>
              <Grid item xs={6}>
                <PersonIcon />
                {panel.people} pers
              </Grid>
              <Grid item xs={6}>
                <WorkIcon />
                {panel.luggages} bagages
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (this.props.passenger) {
      passengerResume = (
        <Grid
          container
          spacing={8}
          style={{
            marginTop: "1.5em",
          }}
        >
          <Grid item xs={6}>
            <b>Prénom</b>
            <br />
            {this.props.passenger.firstName}
          </Grid>
          <Grid item xs={6}>
            <b>Nom</b>
            <br />
            {this.props.passenger.lastName}
          </Grid>
          <Grid item xs={6}>
            <b>Téléphone</b>
            <br />
            {this.props.passenger.phoneNumber}
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <b>Centre de coût</b>
            <br />
            {this.props.passenger.costCenter === "SERVICES"
              ? "Mutuaide Services"
              : "Mutuaide Assistance"}
          </Grid>
          <Grid item xs={6}>
            <b>Numéro de dossier</b>
            <br />
            {this.props.passenger.fileNumber}
          </Grid>
          <Grid item xs={12}>
            <b>Informations complémentaires</b>
            <br />
            {this.props.passenger.comment}
          </Grid>
        </Grid>
      );
    }

    return (
      <div
        style={{ margin: 8, marginBottom: "2em", marginTop: 0, paddingTop: 16 }}
      >
        {directionResume}
        {panelResume}
        {passengerResume}
      </div>
    );
  }
}

export default BookingResume;
