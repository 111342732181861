import React from 'react';

import Lottie from 'react-lottie';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import Grid from '@material-ui/core/Grid';

import PassengerInformationsForm from './../common/PassengerInformationsForm';
import SubmitButton from './../common/SubmitButton';



import loadingAnimation from './loading.json';

class PassengerInformations extends React.Component {

  constructor(props) {
    super(props);
  }

  handleConfirmation(phoneNumber, costCenter, fileNumber, firstName, lastName, comment) {
    //console.log(this.state);
    this.props.onConfirmation(phoneNumber, costCenter, fileNumber, firstName, lastName, comment);
  }

  render() {
    if(this.props.loading) {
      return (
        <div>
        <Lottie options={{
            animationData: loadingAnimation
          }}
          height="50%"
          width="50%"
        />
        </div>
      );
    }

      return (
        <div>
          <PassengerInformationsForm
            loading={this.props.loading}
            submitLabel={'Confirmer la réservation'}
            onConfirmation={(phoneNumber, costCenter, fileNumber, firstName, lastName, comment)=>this.handleConfirmation(phoneNumber, costCenter, fileNumber, firstName, lastName, comment)}
            />
        </div>
      );
  }
}

export default PassengerInformations;
