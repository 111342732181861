import { captureException } from '@sentry/browser';

const polyline = require('@mapbox/polyline');
const token = 'd470e8059856903cf95c806beb352054f1aeacb9';

function direction(origin, destination) {

  return new Promise((res,rej)=>{
    const payload = {
    itineraries: [
      {
        points: [
          origin, destination,
        ],
      }
    ],
    doFastest: true,
    doShortest: false,
  };

  var myInit = { method: 'POST',
                 mode: 'cors',
                 headers: {
                   'content-type': 'application/json',
                   'x-apikey': token,
                 },
                 body: JSON.stringify(payload),
                 cache: 'default' };

  var myRequest = new Request('https://api.allocab.com/geo/v1/trip/directions',myInit);

    fetch(myRequest).then((response)=>{
      return response.json();
    }).then((json)=>{
      console.log(json);
      const encoded = json.results[0].fastestItinerary.fullItinerary.itinerary;
      const decoded = polyline.decode(encoded);

      res({
        polyline: decoded.map((point)=> [point[1],point[0]]),
      });
    }).catch((err)=>{
      captureException(err);
      rej(err);
    })
  });


}

export default {
  direction,
}
