import React from 'react';
import Moment from 'react-moment';
import Lottie from 'react-lottie';
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import Button from '@material-ui/core/Button';

import AccessAlarm from '@material-ui/icons/AccessAlarm';
import ArrowBack from '@material-ui/icons/ArrowBack';

import GeoApi from './../services/GeoApi';
import ShortRoundApi from './../services/ShortRoundApi';
import PanelTranslator from './../services/PanelTranslator';

import SimpleModal from './../components/common/SimpleModal';
import CancelButton from './../components/common/CancelButton';
import PassengerInformationsForm from './../components/common/PassengerInformationsForm';
import PhoneNumber from './../components/common/PhoneNumber';



import TripMap from './../components/trip-map/';

import loadingAnimation from './../loading.json';




const stateDetail = {
  CANCELLED: 'Cette course a été annulée',
  DONE: 'Cette course est à présent terminée',
  ONDUTY: 'En cours',
  ONPAYMENT: 'En cours',
  APPROACHING: 'En approche',
  IN_DA_PLACE: 'Sur-place',
};

const operationnalDetails = {
  ONDUTY: 'Le passager est à bord du véhicule',
  APPROACHING: 'Le chauffeur est en route vers le point de départ',
  IN_DA_PLACE: 'Le chauffeur est au point de départ de la course',
};

function getOperationnalDetail(state) {
  const od = operationnalDetails[state];
  if(od) {
    return od;
  }
  return state;
}

const BackButton = withRouter(({ history }) => (
  <Button
  onClick={() => { history.push('/') }}
  >
  <ArrowBack/>
  </Button>
));

function doTripNeedStatusUpdate(state, globalState, approachingAt, inDaPlaceAt) {
  if(state === 'CANCELLED') {
    return false;
  }
  else if(state === 'DONE') {
    return false;
  }
  else if(state === 'ONPAYMENT') {
    return true;
  }
  else if(state === 'ONDUTY') {
    return true;
  }
  else if(state === 'APPROACHING') {
    return true;
  }
  else if(state === 'IN_DA_PLACE') {
    return true;
  }
  else if(state === 'INCOMING') {
    return false;
  }
  return false;
}

const DriverInformation = (props) => {
  if(!props.driver || !props.driver.vehicle) {
    return <span></span>;
  }
  return (<Paper style={{
    textAlign:'center',
    marginTop: '0.5em',
    borderRadius: '0.25em',
    border: '1px solid #aaa',
    padding:'0.5em',
  }}>
  <Grid container>
  <Grid item xs={6}>
  <p><b>{props.driver.firstName} {props.driver.lastName}</b> <br/><PhoneNumber number={props.driver.phoneNumber}/></p>
  </Grid>
  <Grid item xs={6}>
  <p>{props.driver.vehicle.brand} {props.driver.vehicle.range}<br/>{props.driver.vehicle.licensePlate}</p>
  </Grid>
  </Grid>


  </Paper>);
}

const ETAindicator = (props) =>{
  const {eta} = props;
  if(!eta) {
    return <span></span>
  }
  return <span style={{color:'#000'}}><br/>Arrivée dans {Math.ceil(eta.duration/60)} minute{eta.duration > 60 ? 's' : ''}</span>
};

const stepperItemStyle = {
  textAlign: 'center',
  padding: '0.25em',
  paddingTop: '0.5em',
  paddingBottom: '0.5em',
};

const stepperItemStyleActive = JSON.parse(JSON.stringify(stepperItemStyle));
stepperItemStyleActive.backgroundColor = 'rgb(254, 172, 0)';
stepperItemStyleActive.fontWeight= 'bold';
stepperItemStyleActive.color = '#fff';

const stepperItemStyleDone = JSON.parse(JSON.stringify(stepperItemStyle));
stepperItemStyleDone.backgroundColor = 'rgb(255, 197, 76)';
stepperItemStyleDone.color = '#fff';

const TripStepper = props => {
  return (
    <Grid container alignItems="center" style={{
      fontSize: '0.8em',
      backgroundColor: '#ddd',
      color:'#000',
      borderTopLeftRadius: '0.25em',
      borderTopRightRadius: '0.25em',
      overflow: 'hidden',
    }}>
    <Grid item style={stepperItemStyle} xs={3}>Confirmée</Grid>
    <Grid item style={stepperItemStyle} xs={3}>Approche</Grid>
    <Grid item style={stepperItemStyle} xs={3}>Sur-place</Grid>
    <Grid item style={stepperItemStyleActive} xs={3}>En cours</Grid>
    </Grid>
  );
}

const TripStatus = (props) => {
  const {state, globalState, approachingAt, inDaPlaceAt} = props;
  const tripUpdateNeeded = doTripNeedStatusUpdate(state, globalState, approachingAt, inDaPlaceAt);
  if(!tripUpdateNeeded) {
    return (<div></div>);
  }
  return (<Paper style={{
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: 'white',
    color:'rgb(254, 172, 0)',
    border: '1px solid rgb(254, 172, 0)',
    borderRadius: '0.25em',
    marginTop: '0.5em',
    padding:'0.5em',
  }}>
  <p><InfoIcon/> { getOperationnalDetail(state) }
  <br/>
  <ETAindicator eta={props.eta}/>
  </p>
  </Paper>);
};

function defineNextStatusUpdateTimeout(state) {
  if(state === 'ON_DUTY') {
    return 60;
  }
  if(state === 'APPROACHING') {
    return 10;
  }
  if(state === 'IN_DA_PLACE') {
    return 20;
  }
  return 30;
}

class Fare extends React.Component {

  state = {
    showModal: false,
    modalTitle: 'Confirmation',
    modalMessage: 'Votre demande de modification a bien été prise en compte',
    loadingTrip: true,
    costCenter: '',
    fileNumber: '',
    phoneNumber: '',
    phoneNumberCountry: 'ZZ',
    comment: '',
    state: 'INCOMING',
    firstName: '',
    lastName: '',
  };

  componentWillReceiveProps(nextProps) {
    if(this.props.match.params.tripId !== nextProps.match.params.tripId) {
      console.log('UPDATEEEEEUUUUUU !!!');
      this.loadTrip(nextProps.match.params.tripId);
    }
  }

  componentWillUnmount() {
    this.clearDriverUpdateTimeout();
    this._mounted = false;
  }

  clearDriverUpdateTimeout() {
    if(this.state.driverUpdateTimeout) {
      clearTimeout(this.state.driverUpdateTimeout);
    }
  }


  componentDidMount() {
    console.log(this);
    this._mounted = true;
    const fareId = this.props.match.params.tripId;
    this.loadTrip(fareId);
  }

  getFareId() {
    return this.props.match.params.tripId;
  }


  updateTripStatus() {
    this.clearDriverUpdateTimeout();
    if(!this._mounted) {
      return;
    }
    const fareId = this.props.match.params.tripId;
    ShortRoundApi.getTripStatus(fareId).then((result) =>{
      if(!this._mounted) {
        return;
      }
      if(fareId !== this.getFareId()) {
        return;
      }
      let driverPolyline;
      if(result.positions) {
        driverPolyline = result.positions.map((position)=>{
          return [position.lng, position.lat];
        });
      }

      let to;
      if(!result.archived && 'CANCELLED' !== result.trip.state) {
        to = setTimeout(()=>{
          this.updateTripStatus();
        }, defineNextStatusUpdateTimeout(this.state.state) * 1000);
      }


      this.setState({
        approachingAt: result.trip.approachingAt,
        globalState: result.trip.globalState,
        state: result.trip.state,
        driver: result.driver,
        driverPosition: result.driverPosition,
        driverPolyline,
        eta: result.eta,
        driverUpdateTimeout: to,
      });
    }).catch((err)=>{
      console.log(err);
    });
  }

  loadTrip(fareId) {
    this.clearDriverUpdateTimeout();
    if(!this._mounted) {
      return;
    }

    this.setState({
      loadingTrip: true,
    });

    ShortRoundApi.getTrip(fareId).then((result)=>{
      if(!this._mounted) {
        return;
      }
      if(fareId !== this.getFareId()) {
        return;
      }
      console.log(result);

      const panel = PanelTranslator.getInformations(result.panelName.id);

      const costCenter = result.costCenter ? result.costCenter.replace(new RegExp('MUTUAIDE ', 'g'), '') : '';

      this.setState({
        state: result.state,
        costCenter: costCenter,
        fileNumber: result.fileNumber,
        firstName: result.passengerFirstName,
        lastName: result.passengerLastName,
        phoneNumber: result.passengerPhoneNumber,
        comment: result.comment,
        departureAt: result.departureAt,
        pickup: result.pickup,
        dropoff: result.dropoff,
        panel,
      });

      const pu = {
        lat: result.pickup.position.lat,
        lng: result.pickup.position.lon,
      };
      const dof = {
        lat: result.dropoff.position.lat,
        lng: result.dropoff.position.lon,
      };

      this.updateTripStatus();

      if(result.state !== 'INCOMING') {
        return;
      }
      this.fetchPolyline(pu, dof);

    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      if(!this._mounted) {
        return;
      }
      this.setState({
        loadingTrip: false,
      });
    });
  }


  fetchPolyline(pickup, dropoff) {
    if(!this._mounted) {
      return;
    }
    GeoApi.direction(pickup, dropoff).then((resp)=>{
      if(!this._mounted) {
        return;
      }
      this.setState({
        polyline: resp.polyline,
      });
    }).catch((err)=>{
      console.log(err);
    });
  }

  handleCancel() {
    if(!this._mounted) {
      return;
    }
    const fareId = this.props.match.params.tripId;
    this.setState({
      cancelling: true,
    });

    ShortRoundApi.cancelTrip(fareId).then((result)=>{
      if(fareId !== this.getFareId()) {
        return;
      }
      if(!this._mounted) {
        return;
      }
      //console.log(result);
      this.setState({
        state: 'CANCELLED',
      });
      return this.showModal('Annulation confirmée', 'Votre demande d\'annulation a bien été prise en compte.');
    }).catch((err)=>{
      console.log(err);
    }).then(()=>{
      this.setState({
        cancelling: false,
      });
    });
  }

  resetModal() {
    console.log('resetModal');
    this.setState({
      showModal: false,
    });
  }

  showModal(title, message) {
    this.setState({
      showModal: true,
      modalTitle: title,
      modalMessage: message,
    });
  }

  handleConfirmation(phoneNumber, costCenter, fileNumber, firstName, lastName, comment) {
    if(!this._mounted) {
      return;
    }
    console.log(this.state);

    if(!costCenter) {
      return this.showModal('Information manquante', 'Merci de choisir un centre de coût');
    }
    if(!fileNumber) {
      return this.showModal('Information manquante', 'Merci d\'indiquer un numéro de dossier');
    }
    if(!firstName) {
      return this.showModal('Information manquante', 'Merci d\'indiquer le prénom du passager');
    }
    if(!lastName) {
      return this.showModal('Information manquante', 'Merci d\'indiquer le nom du passager');
    }
    if(!phoneNumber) {
      return this.showModal('Information manquante', 'Merci d\'indiquer le numéro de téléphone du passager');
    }

    this.setState({
      updating: true,
    });
    const fareId = this.props.match.params.tripId;
    ShortRoundApi.updateTrip(fareId, phoneNumber, costCenter, fileNumber, firstName, lastName, comment).then((result)=>{
      if(!this._mounted) {
        return;
      }
      console.log(result);
      return this.showModal('Confirmation', 'Votre demande de modification a bien été prise en compte.');
    }).catch((err)=>{
      console.log(err);
    }).then(()=>{
      this.setState({
        updating: false,
      });
    });
  }

  render() {

    if(this.state.loadingTrip) {
      return (<div>
        <Lottie options={{
          animationData: loadingAnimation
        }}
        height="25%"
        width="25%"
        />
        </div>);
      }

      const fareId = this.props.match.params.tripId;
      const isActionAllowed = this.state.state === 'INCOMING';

      const cancelButton = (<CancelButton
        disabled={this.state.cancelling}
        onClick={()=>this.handleCancel()}
        variant="contained"
        style={{
          padding: '1em',
        }}
        >
        <DeleteIcon/>
        Annuler la réservation</CancelButton>);

        const stateInformation = stateDetail[this.state.state];

        const stateAction = this.state.state === 'INCOMING' ?  cancelButton :   <div style={{color: '#feac00'}}><InfoIcon/>&nbsp;{stateInformation}</div>;

        const {state ,costCenter,fileNumber,firstName,lastName,phoneNumber, phoneNumberCountry,comment, pickup, dropoff, departureAt, panel} = this.state;

        const shouldWeShowDriver = this.state.driver && this.state.state !== 'CANCELLED' && this.state.state !== 'DONE';

        return (
          <div>
          <Paper
          style={{
            padding: '2em',
          }}
          >

          <Grid container  justify="space-between">
          <Grid item>
          <Typography variant="h4">
          <BackButton style={{
            float:'left'
          }}/>
          R{fareId}</Typography>
          </Grid>
          <Grid item>
          {stateAction}
          </Grid>
          </Grid>

          <hr style={{
            marginTop:'2em',
            marginBottom:'2em',
          }}/>

          <Grid
          container
          spacing={8}
          >

          <Grid item xs={4}>

          <Paper
          style={{
            borderRadius: '0.25em',
            //borderTopLeftRadius: 0,
            //borderTopRightRadius: 0,
            border: '1px solid #aaa',
            padding:'0.5em',
          }}
          >
          <Grid
          container
          spacing={8}
          >

          <Grid item xs={8}>
          <b>Départ</b>
          <br/>
          {pickup.formattedAddress}
          </Grid>
          <Grid item xs={4} style={{textAlign:'center'}}>
          <center><AccessAlarm/></center>
          <Moment locale="fr" format="ddd DD MMM HH:mm" fromNow>{departureAt}</Moment>
          </Grid>

          <Grid item xs={8}>
          <b>Arrivée</b> <br/>{dropoff.formattedAddress}
          </Grid>
          <Grid item xs={4} style={{textAlign:'center'}}>
          <center><DirectionsCarIcon/></center>
          <span>{panel.name}</span>
          </Grid>

          </Grid>
          </Paper>

          <TripStatus
          state={this.state.state}
          globalState={this.state.globalState}
          approachingAt={this.state.approachingAt}
          eta={this.state.eta}
          />

          {shouldWeShowDriver ? <DriverInformation driver={this.state.driver}/> : <div></div>}

          </Grid>
          <Grid item xs={8}>

          <TripMap
          pickupPosition={pickup.position}
          dropoffPosition={dropoff.position}
          polyline={this.state.eta ? this.state.eta.polyline : this.state.polyline}
          driverPosition={this.state.driverPosition}
          driverPolyline={this.state.driverPolyline}
          />
          </Grid>

          </Grid>


          <hr style={{
            marginTop:'2em',
            marginBottom:'0em',
          }}/>


          <PassengerInformationsForm
          costCenter={costCenter}
          fileNumber={fileNumber}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          phoneNumberCountry={phoneNumberCountry}
          comment={comment}
          loading={this.state.updating}
          disabled={!isActionAllowed}
          submitLabel={'Enregistrer les modifications'}
          onConfirmation={(phoneNumber, costCenter, fileNumber, firstName, lastName, comment)=>this.handleConfirmation(phoneNumber, costCenter, fileNumber, firstName, lastName, comment)}
          />

          </Paper>
          <SimpleModal
          open={this.state.showModal}
          title={this.state.modalTitle}
          message={this.state.modalMessage}
          handleClose={()=>this.resetModal()}/>
          </div>
        );
      }
    }

    export default Fare;
