import React from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { withStyles } from '@material-ui/core/styles';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
});

const SimpleModal = (props) => {
  const { classes } = props;
  return (
  <div>
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={props.handleClose}
    >
      <div style={getModalStyle()} className={classes.paper}>
      <IconButton aria-label="Clear" style={{float:'right'}} onClick={props.handleClose}>
        <ClearIcon fontSize="small" />
      </IconButton>

        <Typography variant="h6" id="modal-title">
          {props.title}          
        </Typography>
        <Typography variant="subtitle1" id="simple-modal-description">
          {props.message}
        </Typography>
      </div>
    </Modal>
  </div>

  );
}

export default withStyles(styles)(SimpleModal);
