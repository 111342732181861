import React from 'react';

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DriverItem from './DriverListItem';

class DriverList extends React.Component {

  constructor(props) {
    super(props);
  }

  handleItemClicked() {
    if(!this.props.onItemClicked) {
      return;
    }
    this.props.onItemClicked();
  }

  render() {
    const loading = <TableRow><TableCell>chargement</TableCell></TableRow>;

    const items = (
      this.props.drivers.map((driver)=>{
        console.log(driver);
        return (
          <DriverItem
            key={driver.key}
            id={driver.key}
            name={driver.name}
            phoneNumber={driver.phoneNumber}
            />
        );
      })
    );


    if(!this.props.drivers.length && this.props.loading) {
      return (
        <p style={{
            padding:'1em',
            color: '#aaa'
          }}>
          Chargement ...
        </p>
      )
    }

    if(!this.props.drivers.length && !this.props.loading) {
      return (
        <p style={{
            padding:'1em',
            color: '#aaa'
          }}>
          Aucun chauffeurs à afficher.
        </p>
      )
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Telephone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items}
        </TableBody>
      </Table>
    );
  }
}

export default DriverList;
