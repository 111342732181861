import 'idempotent-babel-polyfill';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

import 'moment/locale/fr';



import React from 'react';
import ReactDOM from 'react-dom';
import pack from './../package.json';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { init, captureException } from '@sentry/browser';
import ReactGA from 'react-ga';


init({
  dsn: 'https://37ea5bd4017e4bfb9ac60d43cf8fd584@sentry.io/1307144',
  release: pack.version,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

ReactGA.initialize(process.env.REACT_APP_GA_TRACKER);
ReactGA.pageview(window.location.pathname);

try {
  ReactDOM.render(<App />, document.getElementById('root'));
}
catch(e) {
  captureException(e);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
