import React from "react";
import Lottie from "react-lottie";
import Cookies from "universal-cookie";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SearchAppBar from "./components/SearchAppBar";

import ShortRoundApi from "./services/ShortRoundApi";

import Home from "./views/Home";
import Fare from "./views/Fare";

import loadingAnimation from "./loading.json";

const queryString = require("query-string");

const theme = createMuiTheme({
  palette: {
    //type: 'dark',
    primary: { main: "#feac00" }, // Purple and green play nicely together.
    secondary: { main: "#fff" }, // This is just green.A700 as hex.
  },
  typography: {
    useNextVariants: true,
  },
});

class App extends React.Component {
  state = {
    authing: true,
  };

  componentDidMount() {
    console.log("started App with ENV", process.env.REACT_APP_ENV);
    console.log("API Host", process.env.REACT_APP_SERVER_HOST);
    const cookies = new Cookies();
    const parsed = queryString.parse(window.location.search);
    let authToken = parsed.tk;
    //console.log('authToken in QS', authToken);
    if (!authToken) {
      authToken = cookies.get("tk");
      //console.log('authToken in CK', authToken);
    }
    if (!authToken) {
      throw new Error("NO AUTH");
    }
    cookies.set("tk", authToken);
    //console.log('authToken is', authToken);

    ShortRoundApi.checkAuth(authToken)
      .then(() => {
        this.setState({
          authing: false,
        });
      })
      .catch((err) => {
        this.setState({
          forbidden: true,
        });
      });
  }

  onSearchResults(results, history) {
    console.log("onSearchResults", results);
    if (!results) {
      return;
    }
    const fares = results.fares;
    if (!fares.length) {
      return;
    }

    history.push("/trip/" + fares[0].id);

    //window.location = '/trip/'+fares[0].id;
    //this.props.history.push('/dashboard')
    /*
    this.setState({
      redirectTo: '/trip/'+fares[0].id,
    });
    */
  }

  render() {
    const authing = (
      <div>
        <Lottie
          options={{
            animationData: loadingAnimation,
          }}
          height="25%"
          width="25%"
        />
      </div>
    );

    const forbidden = <div>forbidden</div>;

    const app = (
      <Router>
        <div>
          <SearchAppBar
            onResults={(results, history) => {
              this.onSearchResults(results, history);
            }}
          />
          <div style={{ height: "5rem" }}></div>
          <Grid container justify="center" style={{ backgroundColor: "#eee" }}>
            <Grid item md={12} lg={9} xl={6}>
              <Route exact path="/" component={Home} />
              <Route exact path="/trip/:tripId" component={Fare} />
            </Grid>
          </Grid>
        </div>
      </Router>
    );

    const content = this.state.authing ? authing : app;

    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>{content}</React.Fragment>
      </MuiThemeProvider>
    );
  }
}

export default App;
