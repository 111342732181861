import React from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

import ReactDOM from 'react-dom';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import DateTimePicker from './date-time-picker';

import LocationField from './LocationField';
import TransportNumberField from './TransportNumberField';
import ShortRoundApi from './../../services/ShortRoundApi';

import SubmitButton from './../common/SubmitButton';
const queryString = require("query-string");


class DirectionDefinition extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      departureAt: moment().add(1, 'hour'),
      delay: props.delay || 'ASAP',
      disableTransport: false,
      labelWidth: 0,
    };
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handlePickupSelection(value) {
    this.setState({
        pickup: value,
        disableTransport: false,
    });
    this.props.handlePickupUpdate(value);
  }

  handleDropOffSelection(value) {
    this.setState({
        dropoff: value,
    });
    this.props.handleDropoffUpdate(value);
  }

  handleDelayChange(delay) {
    console.log('handleDelayChange', delay);
    this.setState({
      delay: delay.target.value,
    });
  }

  handleSubmit() {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed.allocabToken);
    const {pickup, dropoff, delay, departureAt, transport} = this.state;
    this.props.confirmDirection({
      pickup, dropoff, delay, departureAt, transport, allocabToken : parsed.allocabToken
    });
  }


  handleDateTimeUpdate(datetime) {
    console.log('date time updated', datetime.format());
    this.setState({
      departureAt: datetime,
    });
  }

  handleTransportUpdated(transport) {
    if(transport.disableTransport) {
      this.setState({
        disableTransport: true,
      });
      return;
    }
    console.log('handleTransportUpdated', transport);
    if(!transport.transport) {
      this.setState({
        delay: 'FUTURE',
        departureAt: moment(transport.date),
      });
      return;
    }
    this.setState({
      delay: 'FUTURE',
      transport: transport.transport,
      departureAt: moment(transport.transport.scheduledArrival).add(30,'minutes'),
    });
  }

  render() {
      const needTransportDetail = !this.state.disableTransport && this.state.pickup && (this.state.pickup.type === 'trainstation' || this.state.pickup.type === 'airport');
      const transport = needTransportDetail ? (<div><TransportNumberField onTransportUpdated={(transport)=> this.handleTransportUpdated(transport)} position={this.state.pickup.position} date={moment()}/></div>) : <div></div>;

      return (
        <form noValidate autoComplete="off">
          <div>
            <LocationField label="Départ" placeholder="Indiquez une adresse de départ" onLocationSelected={(v)=>{this.handlePickupSelection(v)}}/>
          </div>

          { transport }

          <div style={{marginBottom:'0.5rem'}}></div>

          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="delay-auto-width">Quand ?</InputLabel>
            <Select
              value={this.state.delay}
              onChange={(delay)=>{ this.handleDelayChange(delay) }}
              input={<OutlinedInput name="delay" id="delay-auto-width" labelWidth={this.state.labelWidth} />}
            >
              <MenuItem value={'ASAP'}>Au plus tôt</MenuItem>
              <MenuItem value={'FUTURE'}>Pour plus tard</MenuItem>
            </Select>
          </FormControl>

          { this.state.delay === 'FUTURE' ? <DateTimePicker
            onDateTimeUpdated={(datetime)=>this.handleDateTimeUpdate(datetime)}
            date={this.state.departureAt}
            />:<div></div> }


            <div style={{marginBottom:'2.2rem'}}></div>

            <div style={{marginBottom:'1.2rem'}} >
            <LocationField label="Arrivée" placeholder="Indiquez une adresse d'arrivée" onLocationSelected={(v)=>{this.handleDropOffSelection(v)}}/>
            </div>

          <SubmitButton
            onClick={()=>this.handleSubmit()}
            disabled={this.props.loading}
            color="primary" variant="contained" fullWidth size="large" style={{
            marginTop: 8,
          }}>
            {this.props.loading ? '... En cours' : 'Lancer la recherche'}

          </SubmitButton>

        </form>
      )
  }
}

export default DirectionDefinition;
